import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./utils/languages/en.json";
import ar from "./utils/languages/ar.json";
import fr from "./utils/languages/fr.json";
import tr from "./utils/languages/tr.json";
import hi from "./utils/languages/hi.json";
import de from "./utils/languages/de.json";
import ru from "./utils/languages/ru.json";

const fallbackLng = ['en'];
i18n
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        resources: {
            en: {
                translation: en
            },
            tr: {
                translation: tr
            },
            fr: {
                translation: fr
            },
            hi: {
                translation: hi
            },
            ar: {
                translation: ar
            },
            de: {
                translation: de
            },
            ru: {
                translation: ru
            }
        },
        fallbackLng, // fallback language is english.


        debug: false,
        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;