import { Link } from "react-router-dom"
import logo from "../src/timeleft.png"
import { useTranslation } from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <div className='header'>
            <Link to="/" className="logo">
                <img src={logo} />
                <span>WaLog</span>
            </Link>
        </div>
    )
}