import React from 'react';
import ReactDOM from 'react-dom/client';
import { Header } from './Header';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { registerServiceWorker } from './utils/serviceworker'

import "./i18nextConf"
import { Home } from './Pages/Home';
import { Privacy } from './Pages/Privacy';
import { Terms } from './Pages/Terms';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  </>
);
registerServiceWorker()
