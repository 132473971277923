import '../index.css';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import tick from "../../src/tick.png"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header } from '../Header';

export const Home = () => {
  const [sessionBlankData, setSessionBlankData] = useState();
  const [sessionData, setSessionData] = useState();
  const SessionGet = () => {
    fetch(`https://dev.supersky.app/watracker/sessionBlank`)
      .then(res => res.json())
      .then(
        (res) => {
          if (res.sessionId) {
            setSessionBlankData(res);
          }
          else {
            setSessionBlankData(false);
          }
        },
        (error) => {
        }
      )
  }
  const SessionData = () => {
    console.log(sessionBlankData);
    if (sessionBlankData) {
      fetch(`https://dev.supersky.app/watracker/sessionData?sessionId=${sessionBlankData.sessionId}`)
        .then(res => res.json())
        .then(
          (res) => {
            if (res.sessionId) {
              setSessionData(res);
            }
            else {
              setSessionData(false);
            }
          },
          (error) => {
          }
        )
    }
  }
  const [refresh, setRefresh] = useState(null);
  useEffect(() => {
    setRefresh(setInterval(() => {
      SessionData()
    }, 3000))
    return (clearInterval(refresh))
  }, [sessionBlankData])
  useEffect(() => {
    SessionGet();
  }, []);
  const { t } = useTranslation();

  return (
    <div className="home">
      <Header />
      <div className='background'></div>
      <br />
      <br />
      <br />
      {sessionBlankData ?
        <div className='box'>
          {sessionData && sessionData.phoneId != 0 ?
            sessionData.sessionStatus ?
              <div className='verified'>
                <img src={tick} />
                <span className='title'>{t("connected")}</span>
                <span>{t("youcanclose")}</span>
              </div> :
              <div className='scan'>
                <div className='steps'>
                  <span className='title'>{t("scanqrcode")}</span>
                  <span>{t("whatsappstep1")}</span>
                  <span>{t("whatsappstep2")}</span>
                  <span>{t("whatsappstep3")}</span>
                  <span>{t("whatsappstep4")}</span>
                </div>
                {Math.floor(Date.now() / 1000 - sessionData.sessionQrCodeUpdateDate) < 60 ? <QRCode value={sessionData.sessionQrCode} /> : <Skeleton width={256} height={256} />}
              </div>
            : <div className='verify'>
              <span className='title'>{t("entercode")}</span>
              <div className='code'>
                {sessionBlankData.sessionVerificationCode.toString().split("").map(function (item, i) { return <span className='pin'>{item}</span> })}
              </div>
            </div>
          }
        </div>
        :
        <div className='box'><Skeleton width={120} height={24} /></div>}


      <div className='download'>
        <span className='title'>{t("trackfamilyfriends")}</span>
        <div className='stores'>
          <a className='store' href="https://play.google.com/store/apps/details?id=com.wa.last.seen.online.status.tracker.monitor.walog" target='_blank'>
            <img className='icon' src="googleplay.png" />
            <div className='details'>
              <span className='alt'>{t("getiton")}</span>
              <span className='text'>Google Play</span>
            </div>
          </a>
          <a className='store' href="https://apps.apple.com/de/app/parebot-online-monitor/id6472949382" target='_blank'>
            <img className='icon' src="apple.png" />
            <div className='details'>
              <span className='alt'>{t("downloadonthe")}</span>
              <span className='text'>App Store</span>
            </div>
          </a>
        </div>
      </div>
      <div className='features'>
        <div className='feature feature-left'>
          <img className='cover' src="f1.png" />
          <div className='details'>
            <span className='title'>{t("featuretitle1")}</span>
            <span className='description'>{t("featuredescription1")}</span>
          </div>
        </div>
        <div className='feature feature-right'>
          <div className='details'>
            <span className='title'>{t("featuretitle2")}</span>
            <span className='description'>{t("featuredescription2")}</span>
          </div>
          <img className='cover' src="f2.png" />
        </div>
        <div className='feature feature-left'>
          <img className='cover' src="f3.png" />
          <div className='details'>
            <span className='title'>{t("featuretitle3")}</span>
            <span className='description'>{t("featuredescription3")}</span>
          </div>
        </div>
        <div className='feature feature-right'>
          <div className='details'>
            <span className='title'>{t("featuretitle4")}</span>
            <span className='description'>{t("featuredescription4")}</span>
          </div>
          <img className='cover' src="f4.png" />
        </div>
        <div className='feature feature-left'>
          <img className='cover' src="f5.png" />
          <div className='details'>
            <span className='title'>{t("featuretitle5")}</span>
            <span className='description'>{t("featuredescription5")}</span>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div >
  )
}
